import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapActions } from 'vuex'
import api from '../../api'
import { LoginActionPayload } from '../../store/actions'
import * as types from '../../store/types'

@Component({
  methods: {
    ...mapActions({
      login: types.LOGIN,
    }),
  },
})
export default class MllwLoginView extends Vue {
  login!: (payload: LoginActionPayload) => Promise<account.TCurrent>

  username: string = ''
  password: string = ''
  rememberMe: boolean = false
  errors: string[] = []

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  beforeCreate() {
   // alert(process.env.NODE_ENV)
  }



  created() {
    this.username = localStorage.username
    this.rememberMe = localStorage.rememberMe === 'true'
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  async handleLogin(e: Event) {
    e.preventDefault()
    this.errors = []
    if (!this.checkForm(e)) throw new Error('[LOGIN_CANCELED_ERROR]')
    const { username, password, rememberMe } = this
    try {
      const result = await this.login({ username, password })
      localStorage.username = rememberMe ? username : ''
      localStorage.rememberMe = rememberMe

      const { returnUrl, ...etc } = this.$route.query
      console.log('returnUrl : ' + returnUrl)
      if (returnUrl && typeof returnUrl === 'string') {
        this.$router.push({
          path: decodeURIComponent(returnUrl),
          query: etc,
        })
      } else {
        this.$router.push({
          path: '/',
          query: etc,
        })
      }
    } catch (e) {
      const serverErrors = (e as any).data?.errors
      if (serverErrors) {
        this.errors = [...this.errors, ...serverErrors]
      } else {
        this.errors.push(e as any)
      }
    }
  }

  checkForm(e: Event): boolean {
    this.errors = []

    if (!this.username) {
      this.errors.push('아이디를 입력하세요.')
    }
    if (this.username?.indexOf('@') != -1 && !this.validEmail(this.username)) {
      this.errors.push('올바른 이메일 형식이 아닙니다.')
    } else if (!this.password) {
      this.errors.push('암호가 필요합니다.')
    }

    if (this.errors.length) {
      return false
    } else {
      e.preventDefault()
      return true
    }
  }

  validEmail(email: string): boolean {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  changePassword() {
    window.open('https://pss.isu.co.kr')
  }
}
